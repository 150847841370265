@tailwind base;
@tailwind components;
@tailwind utilities;

/* English Language Fonts */
@font-face {
    font-family: 'Figtree-Light';
    src: url('../public/fonts/Figtree-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'AksharyoginiNormal';
    src: url('../public/fonts/AksharyoginiNormal.ttf') format('truetype');
}

@font-face {
    font-family: 'AksharyoginiBold';
    src: url('../public/fonts/AksharyoginiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Regular';
    src: url('../public/fonts/Figtree-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Medium';
    src: url('../public/fonts/Figtree-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-SemiBold';
    src: url('../public/fonts/Figtree-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Bold';
    src: url('../public/fonts/Figtree-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-ExtraBold';
    src: url('../public/fonts/Figtree-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Black';
    src: url('../public/fonts/Figtree-Black.ttf') format('truetype');
}

/* English Language Fonts - Italic */
@font-face {
    font-family: 'Figtree-LightItalic';
    src: url('../public/fonts/Figtree-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-RegularItalic';
    src: url('../public/fonts/Figtree-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-MediumItalic';
    src: url('../public/fonts/Figtree-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-SemiBoldItalic';
    src: url('../public/fonts/Figtree-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-BoldItalic';
    src: url('../public/fonts/Figtree-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-ExtraBoldItalic';
    src: url('../public/fonts/Figtree-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-BlackItalic';
    src: url('../public/fonts/Figtree-BlackItalic.ttf') format('truetype');
}

/* English Language Fonts - Mono */
@font-face {
    font-family: 'SplineSansMono-Light';
    src: url('../public/fonts/SplineSansMono-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Regular';
    src: url('../public/fonts/SplineSansMono-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Medium';
    src: url('../public/fonts/SplineSansMono-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-SemiBold';
    src: url('../public/fonts/SplineSansMono-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Bold';
    src: url('../public/fonts/SplineSansMono-Bold.ttf') format('truetype');
}

/* English Language Fonts - Mono Italic */
@font-face {
    font-family: 'SplineSansMono-LightItalic';
    src: url('../public/fonts/SplineSansMono-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-RegularItalic';
    src: url('../public/fonts/SplineSansMono-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-MediumItalic';
    src: url('../public/fonts/SplineSansMono-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-SemiBoldItalic';
    src: url('../public/fonts/SplineSansMono-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-BoldItalic';
    src: url('../public/fonts/SplineSansMono-BoldItalic.ttf') format('truetype');
}

/* Devanagari Language Fonts */
@font-face {
    font-family: 'NotoSerifDevanagari-Light';
    src: url('../public/fonts/NotoSerifDevanagari-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'AksharyoginiNormal';
    src: url('../public/fonts/AksharyoginiNormal.ttf') format('truetype');
}

@font-face {
    font-family: 'AksharyoginiBold';
    src: url('../public/fonts/AksharyoginiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Regular';
    src: url('../public/fonts/NotoSerifDevanagari-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Medium';
    src: url('../public/fonts/NotoSerifDevanagari-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-SemiBold';
    src: url('../public/fonts/NotoSerifDevanagari-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Bold';
    src: url('../public/fonts/NotoSerifDevanagari-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-ExtraBold';
    src: url('../public/fonts/NotoSerifDevanagari-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Black';
    src: url('../public/fonts/NotoSerifDevanagari-Black.ttf') format('truetype');
}

::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    border-radius: 100px;
    padding: 0.5px;
}

::-webkit-scrollbar-track-piece {
    background-color: #FFF;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    border: 2px solid #FFF;
    background-color: #ff964d;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
}

@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide Scrollbar */
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .thin-scrollbar::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        border-radius: 100px;
    }

    /* Show scrollbar for Chrome, Safari and Opera */
    .show-scrollbar::-webkit-scrollbar {
        display: block;
    }

    /* Show scrollbar for IE, Edge and Firefox */
    .show-scrollbar {
        -ms-overflow-style: auto; /* IE and Edge */
        scrollbar-width: auto; /* Firefox */
    }
}

#arrow-icon-tip {
    transform: translateX(-0.1vw);
    transition: all 150ms ease-in-out;
}

#arrow-icon-line {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.arrow-button:hover #arrow-icon-tip {
    transform: translateX(0vw);
}

.arrow-button:hover #arrow-icon-line {
    opacity: 1;
}

.react-flow__handle.connection {
    background: #ff6060;
}

.react-flow__handle.valid {
    background: #55dd99;
}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="month"]::-webkit-calendar-picker-indicator {
    /* Customize the icon here */
    /*margin-left: -10px;*/
    background-image: url('../public/assets/calendar-icon-full.svg');
    background-size: cover;
}

input[type="month"]::-webkit-calendar-picker-indicator {
    /*margin-left: -2px; !* Adjust value as needed *!*/
}

.filters-box-shadow {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-modal-container {
    @apply bg-white flex flex-col
    portrait:rounded-none portrait:md:rounded-lg landscape:rounded-lg;

    @apply portrait:min-w-full portrait:w-full portrait:max-w-full
    portrait:min-h-full portrait:h-full portrait:max-h-full
    portrait:md:min-w-[35vw] portrait:md:h-fit min-w-[35vw];
}

.form-modal-header-container {
    @apply flex flex-col;

    @apply portrait:px-[1.25rem] portrait:pt-[1rem]
    portrait:jk:px-[1.75rem] portrait:jk:pt-[1.5rem]
    portrait:lg:px-[2.25rem] portrait:lg:pt-[2rem];

    @apply landscape:px-[0.75rem] landscape:pt-[0.75rem]
    landscape:jk:px-[0.9rem] landscape:jk:pt-[0.9rem]
    landscape:lg:px-[1.15rem] landscape:lg:pt-[1.15rem];

    @apply portrait:gap-y-[1rem] landscape:gap-y-[0.75rem]
    portrait:jk:gap-y-[1.5rem] landscape:jk:gap-y-[0.9rem]
    portrait:lg:gap-y-[2rem] landscape:lg:gap-y-[1.15rem];
}

.form-modal-header-container div h2, .form-modal-header-container h2 {
    @apply font-custom-semi-bold leading-tight;

    @apply portrait:text-[1.5rem] portrait:md:text-[1.375rem]
    portrait:jk:text-[1.5rem] portrait:lg:text-[2rem];

    @apply landscape:text-[0.65rem] landscape:md:text-[0.875rem]
    landscape:jk:text-[1rem] landscape:lg:text-[1.225rem];
}

.form-modal-header-container div button {
    @apply flex items-center justify-center rounded-lg
    bg-[#F5F5F5] hover:bg-[#F7F7F7] aspect-square;

    @apply portrait:hover:ring-4 landscape:hover:ring-2
    landscape:jk:hover:ring-4 hover:ring-[#D4D4D4]
    landscape:rounded-[0.2rem] landscape:jk:rounded-md
    transition duration-300 ease-out;

    @apply portrait:w-[2rem] portrait:md:w-[1.75rem]
    portrait:jk:w-[2rem] portrait:lg:w-[2.25rem];

    @apply landscape:w-[0.75rem] landscape:sm:w-[0.95rem]
    landscape:jk:w-[1.25rem] landscape:lg:w-[1.75rem];
}

.form-modal-body-container {
    @apply overflow-y-scroll portrait:md:thin-scrollbar landscape:thin-scrollbar
    flex flex-col flex-grow;

    @apply landscape:max-h-[60vh] portrait:flex-1 portrait:md:max-h-[60vh]
    portrait:gap-[1rem] portrait:jk:gap-[1.25rem] portrait:lg:gap-[1.75rem]
    landscape:gap-[0.75rem] landscape:jk:gap-[0.9rem] landscape:lg:gap-[1rem];

    @apply portrait:pl-[1.25rem] portrait:pr-[0.75rem] portrait:py-[1rem]
    portrait:jk:pl-[1.75rem] portrait:jk:pr-[1.5rem] portrait:jk:py-[1.5rem]
    portrait:lg:pl-[2.25rem] portrait:lg:pr-[2rem] portrait:lg:py-[2rem];

    @apply landscape:pl-[0.75rem] landscape:pr-[0.5rem] landscape:py-[0.5rem]
    landscape:jk:pl-[0.9rem] landscape:jk:pr-[0.65rem] landscape:jk:py-[0.6rem]
    landscape:lg:pl-[1.15rem] landscape:lg:pr-[0.95rem] landscape:lg:py-[0.75rem];
}

.form-modal-footer-container {
    @apply flex flex-col;

    @apply portrait:px-[1.25rem] portrait:pb-[1rem]
    portrait:jk:px-[1.75rem] portrait:jk:pb-[1.5rem]
    portrait:lg:px-[2.25rem] portrait:lg:pb-[2rem];

    @apply landscape:px-[0.75rem] landscape:pb-[0.75rem]
    landscape:jk:px-[0.9rem] landscape:jk:pb-[0.9rem]
    landscape:lg:px-[1.15rem] landscape:lg:pb-[1.15rem];

    @apply portrait:gap-y-[1rem] landscape:gap-y-[0.75rem]
    portrait:jk:gap-y-[1.5rem] landscape:jk:gap-y-[0.9rem]
    portrait:lg:gap-y-[2rem] landscape:lg:gap-y-[1.15rem];
}

.form-modal-footer-container div button {
    @apply inline-flex items-center arrow-button font-custom-medium
    hover:ring-4 hover:ring-offset-1
    disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full;

    @apply portrait:gap-x-[0.45rem] portrait:sm:gap-x-[0.4rem] portrait:jk:gap-x-[0.4rem]
    landscape:gap-x-[0.35rem] landscape:jk:gap-x-[0.5rem];

    @apply portrait:py-[0.45rem] portrait:sm:py-[0.4rem] portrait:jk:py-[0.45rem];

    @apply landscape:pl-[0.85rem] landscape:pr-[0.35rem] landscape:py-[0.3rem]
    landscape:jk:pl-[1.05rem] landscape:jk:pr-[0.4rem] landscape:jk:py-[0.5rem];

    @apply transition-all duration-300 ease-in-out;
}

.form-modal-footer-container-delete-button {
    @apply bg-red-500 hover:bg-red-600 hover:ring-red-300;
    @apply portrait:px-[0.95rem] portrait:sm:px-[1.25rem] portrait:jk:px-[1.15rem];
}

.form-modal-footer-container-submit-button {
    @apply bg-green-400 hover:bg-green-500 hover:ring-green-300;
    @apply portrait:pl-[0.95rem] portrait:sm:pl-[1.25rem] portrait:jk:pl-[1.15rem];
    @apply portrait:pr-[0.65rem] portrait:sm:pr-[0.5rem] portrait:jk:pr-[0.5rem];
}

.form-modal-footer-container div button p {
    @apply font-custom-medium text-gray-900 leading-tight
    line-clamp-1 overflow-x-scroll no-scrollbar;

    @apply portrait:text-[1rem] portrait:md:text-[1.25rem] portrait:jk:text-[1.15rem]
    landscape:text-[0.8rem] landscape:md:text-[0.85rem] landscape:jk:text-[1rem];
}

.form-modal-footer-container div button img {
    @apply aspect-square;
    @apply portrait:h-[0.95rem] portrait:md:h-[0.95rem] portrait:jk:h-[0.9rem];
    @apply landscape:h-[0.65rem] landscape:w-[0.65rem]
    landscape:md:h-[0.75rem] landscape:md:w-[0.75rem]
    landscape:jk:h-[0.85rem] landscape:jk:w-[0.85rem];
}

.form-modal-footer-container div button svg {
    @apply portrait:h-[0.75rem] portrait:w-[0.75rem]
    portrait:md:h-[0.95rem] portrait:md:w-[0.95rem]
    portrait:jk:h-[0.9rem] portrait:jk:w-[0.9rem];

    @apply landscape:h-[0.65rem] landscape:w-[0.65rem]
    landscape:md:h-[0.75rem] landscape:md:w-[0.75rem]
    landscape:jk:h-[0.85rem] landscape:jk:w-[0.85rem];
}

.delete-modal-container {
    @apply bg-white flex flex-col rounded-lg;
    @apply portrait:min-w-[80vw] portrait:w-[80vw] portrait:max-w-[80vw]
    portrait:md:min-w-[30vw] portrait:md:w-[30vw] portrait:md:max-w-[30vw]
    landscape:min-w-[30vw] landscape:w-[30vw] landscape:max-w-[30vw];
}

.delete-modal-header-container {
    @apply flex flex-col;

    @apply portrait:px-[1.25rem] portrait:pt-[1rem]
    portrait:jk:px-[1.75rem] portrait:jk:pt-[1.5rem]
    portrait:lg:px-[2.25rem] portrait:lg:pt-[2rem];

    @apply landscape:px-[0.75rem] landscape:pt-[0.75rem]
    landscape:jk:px-[0.9rem] landscape:jk:pt-[0.9rem]
    landscape:lg:px-[1.15rem] landscape:lg:pt-[1.15rem];

    @apply portrait:gap-y-[1rem] landscape:gap-y-[0.75rem]
    portrait:jk:gap-y-[1.5rem] landscape:jk:gap-y-[0.9rem]
    portrait:lg:gap-y-[2rem] landscape:lg:gap-y-[1.15rem];
}

.delete-modal-header-container div h2 {
    @apply font-custom-semi-bold leading-tight;

    @apply portrait:text-[1.5rem] portrait:md:text-[1.375rem]
    portrait:jk:text-[1.5rem] portrait:lg:text-[2rem];

    @apply landscape:text-[0.65rem] landscape:md:text-[0.875rem]
    landscape:jk:text-[1rem] landscape:lg:text-[1.225rem];
}

.delete-modal-header-container div button {
    @apply flex items-center justify-center rounded-lg
    bg-[#F5F5F5] hover:bg-[#F7F7F7] aspect-square;

    @apply portrait:hover:ring-4 landscape:hover:ring-2
    landscape:jk:hover:ring-4 hover:ring-[#D4D4D4]
    landscape:rounded-[0.2rem] landscape:jk:rounded-md
    transition duration-300 ease-out;

    @apply portrait:w-[2rem] portrait:md:w-[1.75rem]
    portrait:jk:w-[2rem] portrait:lg:w-[2.25rem];

    @apply landscape:w-[0.75rem] landscape:sm:w-[0.95rem]
    landscape:jk:w-[1.25rem] landscape:lg:w-[1.75rem];
}

.delete-modal-body-container {
    @apply overflow-y-scroll portrait:md:thin-scrollbar landscape:thin-scrollbar
    flex-1 flex flex-col flex-grow;

    @apply landscape:max-h-[60vh] portrait:flex-1 portrait:md:max-h-[60vh]
    portrait:gap-[1rem] portrait:jk:gap-[1.25rem] portrait:lg:gap-[1.75rem]
    landscape:gap-[0.75rem] landscape:jk:gap-[0.9rem] landscape:lg:gap-[1rem];

    @apply portrait:pl-[1.25rem] portrait:pr-[0.75rem] portrait:py-[1rem]
    portrait:jk:pl-[1.75rem] portrait:jk:pr-[1.5rem] portrait:jk:py-[1.5rem]
    portrait:lg:pl-[2.25rem] portrait:lg:pr-[2rem] portrait:lg:py-[2rem];

    @apply landscape:pl-[0.75rem] landscape:pr-[0.5rem] landscape:py-[0.5rem]
    landscape:jk:pl-[0.9rem] landscape:jk:pr-[0.65rem] landscape:jk:py-[0.6rem]
    landscape:lg:pl-[1.15rem] landscape:lg:pr-[0.95rem] landscape:lg:py-[0.75rem];
}

.delete-modal-body-container p {
    @apply portrait:text-[0.9rem] portrait:md:text-[1rem] portrait:jk:text-[1.2rem] portrait:lg:text-[1.5rem];
    @apply landscape:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-tight font-custom-medium text-gray-900;
}

.delete-modal-footer-container {
    @apply flex flex-col;

    @apply portrait:px-[1.25rem] portrait:pb-[1rem]
    portrait:jk:px-[1.75rem] portrait:jk:pb-[1.5rem]
    portrait:lg:px-[2.25rem] portrait:lg:pb-[2rem];

    @apply landscape:px-[0.75rem] landscape:pb-[0.75rem]
    landscape:jk:px-[0.9rem] landscape:jk:pb-[0.9rem]
    landscape:lg:px-[1.15rem] landscape:lg:pb-[1.15rem];

    @apply portrait:gap-y-[1rem] landscape:gap-y-[0.75rem]
    portrait:jk:gap-y-[1.5rem] landscape:jk:gap-y-[0.9rem]
    portrait:lg:gap-y-[2rem] landscape:lg:gap-y-[1.15rem];
}

.delete-modal-footer-container div button {
    @apply inline-flex items-center arrow-button font-custom-medium
    hover:ring-4 hover:ring-offset-1
    disabled:cursor-not-allowed disabled:bg-slate-400/50 rounded-full;

    @apply portrait:gap-x-[0.45rem] portrait:sm:gap-x-[0.4rem] portrait:jk:gap-x-[0.4rem]
    landscape:gap-x-[0.35rem] landscape:jk:gap-x-[0.5rem];

    @apply portrait:py-[0.45rem] portrait:sm:py-[0.4rem] portrait:jk:py-[0.45rem];

    @apply landscape:py-[0.3rem]
    landscape:jk:py-[0.5rem];

    @apply transition-all duration-300 ease-in-out;
}

.delete-modal-footer-container-delete-button {
    @apply bg-red-500 hover:bg-red-600 hover:ring-red-300 border-2 border-red-700;
    @apply portrait:px-[0.95rem] portrait:sm:px-[1.25rem] portrait:jk:px-[1.15rem];
    @apply landscape:px-[0.85rem] landscape:jk:px-[1.05rem];
}

.delete-modal-footer-container-cancel-button {
    @apply bg-white hover:bg-green-100 border-2 border-green-400 hover:ring-green-300 ;
    @apply portrait:pl-[0.95rem] portrait:sm:pl-[1.25rem] portrait:jk:pl-[1.15rem];
    @apply portrait:pr-[0.65rem] portrait:sm:pr-[0.5rem] portrait:jk:pr-[0.5rem];
    @apply landscape:pl-[0.85rem] landscape:pr-[0.35rem]
    landscape:jk:pl-[1.05rem] landscape:jk:pr-[0.4rem];
}

.delete-modal-footer-container div button p {
    @apply font-custom-medium text-gray-900 leading-tight
    line-clamp-1 overflow-x-scroll no-scrollbar;

    @apply portrait:text-[1rem] portrait:md:text-[1.25rem] portrait:jk:text-[1.15rem]
    landscape:text-[0.8rem] landscape:md:text-[0.85rem] landscape:jk:text-[1rem];
}

.delete-modal-footer-container div button img {
    @apply aspect-square;
    @apply portrait:h-[0.95rem] portrait:md:h-[0.95rem] portrait:jk:h-[0.9rem];
    @apply landscape:h-[0.65rem] landscape:w-[0.65rem]
    landscape:md:h-[0.75rem] landscape:md:w-[0.75rem]
    landscape:jk:h-[0.85rem] landscape:jk:w-[0.85rem];
}

.delete-modal-footer-container div button svg {
    @apply portrait:h-[0.75rem] portrait:w-[0.75rem]
    portrait:md:h-[0.95rem] portrait:md:w-[0.95rem]
    portrait:jk:h-[0.9rem] portrait:jk:w-[0.9rem];

    @apply landscape:h-[0.65rem] landscape:w-[0.65rem]
    landscape:md:h-[0.75rem] landscape:md:w-[0.75rem]
    landscape:jk:h-[0.85rem] landscape:jk:w-[0.85rem];
}

.custom-input-container {
    @apply w-full flex flex-col;
    @apply portrait:gap-y-[0.5rem] portrait:md:gap-y-[0.25rem] portrait:jk:gap-y-[0.45rem] portrait:lg:gap-y-[0.75rem];
    @apply landscape:gap-y-[0.1rem] landscape:md:gap-y-[0.15rem] landscape:jk:gap-y-[0.2rem] landscape:lg:gap-y-[0.3rem];
}

.custom-input-container label {
    @apply flex gap-1 items-center font-custom-medium leading-6 text-gray-600;
    @apply portrait:text-[1rem] portrait:md:text-[0.8rem] portrait:jk:text-[1rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.65rem] landscape:md:text-[0.7rem] landscape:jk:text-[0.75rem] landscape:lg:text-[0.85rem];
}

.custom-input-container label img {
    @apply aspect-square;
    @apply portrait:h-[1rem] portrait:md:h-[0.525rem] portrait:jk:h-[0.6rem] portrait:lg:h-[0.675rem];
    @apply landscape:h-[0.8rem] landscape:md:h-[0.95rem] landscape:jk:h-[1.1rem] landscape:lg:h-[1.25rem];
}

.custom-input-multiple-fields-container {
    @apply flex justify-between portrait:flex-col portrait:md:flex-row landscape:flex-row;
    @apply portrait:gap-[1rem] portrait:md:gap-[0.6rem] portrait:jk:gap-[0.75rem] portrait:lg:gap-[0.85rem];
    @apply landscape:gap-[0.4rem] landscape:md:gap-[0.45rem] landscape:jk:gap-[0.5rem] landscape:lg:gap-[0.75rem];
}

.custom-input {
    @apply flex-1 shadow-sm w-full;
    @apply landscape:rounded-md portrait:rounded-md portrait:jk:rounded-lg;

    @apply portrait:px-[0.95rem] portrait:py-[0.5rem] portrait:md:px-[0.75rem] portrait:md:py-[0.45rem] portrait:jk:px-[0.85rem] portrait:jk:py-[0.55rem] portrait:lg:px-[1rem] portrait:lg:py-[0.75rem];
    @apply landscape:px-[0.75rem] landscape:py-[0.35rem] landscape:jk:px-[0.85rem] landscape:jk:py-[0.4rem] landscape:lg:px-[1rem] landscape:lg:py-[0.5rem];

    @apply portrait:text-[1.25rem] portrait:md:text-[1rem] portrait:jk:text-[1.2rem] portrait:lg:text-[1.5rem];
    @apply landscape:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-tight font-custom-regular text-gray-900 border border-gray-300 outline-none;

    @apply focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40] transition-all duration-300 ease-in-out;
}

.custom-input-label-checkbox {
    @apply w-full flex flex-row items-center
    portrait:gap-x-[0.5rem] portrait:md:gap-x-[0.6rem] portrait:jk:gap-x-[0.75rem] portrait:lg:gap-x-[0.85rem]
    landscape:gap-x-[0.4rem] landscape:md:gap-x-[0.45rem] landscape:jk:gap-x-[0.5rem] landscape:lg:gap-x-[0.75rem];
}

.custom-input-label-checkbox p {
    @apply portrait:text-[1.15rem] portrait:md:text-[1rem] portrait:jk:text-[1.2rem] portrait:lg:text-[1.5rem];
    @apply landscape:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-tight font-custom-regular text-gray-900;
}

.custom-input-label-checkbox img {
    @apply aspect-auto;
    @apply portrait:w-[1.35rem] portrait:md:w-[1.1rem] portrait:jk:w-[1.2rem] portrait:lg:w-[1.6rem];
    @apply landscape:w-[0.9rem] landscape:md:w-[1rem] landscape:jk:w-[1.1rem] landscape:lg:w-[1.25rem];
}

/* Custom Dropdown Button */
.custom-input-dropdown-button {
    @apply flex-1 shadow-sm w-full border border-gray-300 outline-none;
    @apply flex justify-between items-center;
    @apply landscape:rounded-md portrait:rounded-md portrait:jk:rounded-lg;

    @apply portrait:pl-[0.95rem] portrait:pr-[0.45rem] portrait:py-[0.5rem]
    portrait:md:pl-[0.7rem] portrait:md:pr-[0.35rem] portrait:md:py-[0.45rem]
    portrait:jk:pl-[0.9rem] portrait:jk:pr-[0.45rem] portrait:jk:py-[0.55rem]
    portrait:lg:pl-[1.1rem] portrait:lg:pr-[0.55rem] portrait:lg:py-[0.75rem];

    @apply landscape:pl-[0.65rem] landscape:pr-[0.4rem] landscape:py-[0.35rem]
    landscape:jk:pl-[0.75rem] landscape:jk:pr-[0.45rem] landscape:jk:py-[0.4rem]
    landscape:lg:pl-[0.85rem] landscape:lg:pr-[0.55rem] landscape:lg:py-[0.5rem];

    @apply focus:ring-4 focus:ring-offset-1 focus:ring-[#5F0F40] transition-all duration-300 ease-in-out;
}

.custom-input-dropdown-button:focus-within {
    @apply ring-4 ring-offset-1 ring-[#5F0F40];
}

.custom-input-dropdown-button div {
    @apply flex-1 flex items-center;
    @apply portrait:gap-[0.5rem] portrait:md:gap-[0.25rem] portrait:jk:gap-[0.45rem] portrait:lg:gap-[0.75rem];
    @apply landscape:gap-[0.1rem] landscape:md:gap-[0.15rem] landscape:jk:gap-[0.2rem] landscape:lg:gap-[0.3rem];
}

.custom-input-dropdown-button input {
    @apply w-full flex-1 flex justify-between items-center;
    @apply landscape:rounded-r-md portrait:rounded-r-md portrait:jk:rounded-r-lg;

    @apply portrait:text-[1.25rem] portrait:md:text-[0.85rem] portrait:jk:text-[1.15rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.725rem] landscape:md:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-[1] font-custom-regular text-gray-900;

    @apply transition-all duration-300 ease-in-out outline-none;
}

.custom-input-dropdown-button div p, .custom-input-dropdown-button p {
    @apply portrait:text-[1.25rem] portrait:md:text-[1rem] portrait:jk:text-[1.2rem] portrait:lg:text-[1.5rem];
    @apply landscape:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-tight font-custom-regular text-gray-900;
}

.custom-input-dropdown-button div img {
    @apply aspect-auto;
    @apply portrait:h-[1.5rem] portrait:md:h-[1.1rem] portrait:jk:h-[1.2rem] portrait:lg:h-[1.6rem];
    @apply landscape:h-[0.9rem] landscape:md:h-[1rem] landscape:jk:h-[1.1rem] landscape:lg:h-[1.25rem];
}

.custom-input-dropdown-button svg {
    @apply aspect-square transition-all duration-300 ease-in-out;
    @apply portrait:w-[1rem] portrait:md:w-[0.525rem] portrait:jk:w-[0.6rem] portrait:lg:w-[0.675rem];
    @apply landscape:w-[0.5rem] landscape:md:w-[0.6rem] landscape:jk:w-[0.675rem] landscape:lg:w-[0.725rem];
}

.custom-input-dropdown-list-button {
    @apply w-full flex items-center gap-1 hover:bg-gray-300;

    @apply portrait:px-[0.95rem] portrait:md:px-[0.75rem] portrait:jk:px-[0.85rem] portrait:lg:px-[1rem]
    portrait:py-[0.5rem] portrait:md:py-[0.45rem] portrait:jk:py-[0.55rem] portrait:lg:py-[0.75rem];

    @apply landscape:px-[0.75rem] landscape:jk:px-[0.85rem] landscape:lg:px-[1rem]
    landscape:py-[0.35rem] landscape:jk:py-[0.4rem] landscape:lg:py-[0.5rem];

    @apply portrait:gap-[0.5rem] portrait:md:gap-[0.25rem] portrait:jk:gap-[0.45rem] portrait:lg:gap-[0.75rem]
    landscape:gap-[0.1rem] landscape:md:gap-[0.15rem] landscape:jk:gap-[0.2rem] landscape:lg:gap-[0.3rem];
}

.custom-input-dropdown-list-button p {
    @apply portrait:text-[1.25rem] portrait:md:text-[1rem] portrait:jk:text-[1.2rem] portrait:lg:text-[1.5rem];
    @apply landscape:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-tight font-custom-regular text-gray-900;
}

.custom-input-dropdown-list-button img {
    @apply aspect-auto;
    @apply portrait:h-[1rem] portrait:md:h-[1.1rem] portrait:jk:h-[1.2rem] portrait:lg:h-[1.6rem];
    @apply landscape:h-[0.9rem] landscape:md:h-[1rem] landscape:jk:h-[1.1rem] landscape:lg:h-[1.25rem];
}

.custom-phone-number-input {
    @apply w-full flex flex-col;
    @apply portrait:gap-y-[0.5rem] portrait:md:gap-y-[0.25rem] portrait:jk:gap-y-[0.45rem] portrait:lg:gap-y-[0.75rem];
    @apply landscape:gap-y-[0.1rem] landscape:md:gap-y-[0.15rem] landscape:jk:gap-y-[0.2rem] landscape:lg:gap-y-[0.3rem];
}

.custom-phone-number-input label {
    @apply block font-custom-medium leading-6 text-gray-600;
    @apply portrait:text-[1rem] portrait:md:text-[0.8rem] portrait:jk:text-[1rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.65rem] landscape:md:text-[0.7rem] landscape:jk:text-[0.75rem] landscape:lg:text-[0.85rem];
}

.custom-phone-number-input-container {
    @apply shadow-sm w-full flex-1 flex justify-between items-center;
    @apply landscape:rounded-md portrait:rounded-md portrait:jk:rounded-lg;
    @apply border border-gray-300 outline-none;
    @apply transition-all duration-300 ease-in-out;
}

.custom-phone-number-input-container:focus-within {
    @apply ring-4 ring-offset-1 ring-[#5F0F40];
}

.custom-phone-number-input-container button {
    @apply h-full flex justify-between items-center bg-gray-300;
    @apply landscape:rounded-l-[0.3rem] portrait:rounded-l-[0.3rem] portrait:jk:rounded-l-md;

    @apply portrait:pl-[0.95rem] portrait:pr-[0.45rem] portrait:py-[0.5rem]
    portrait:md:pl-[0.7rem] portrait:md:pr-[0.35rem] portrait:md:py-[0.45rem]
    portrait:jk:pl-[0.9rem] portrait:jk:pr-[0.45rem] portrait:jk:py-[0.55rem]
    portrait:lg:pl-[1.1rem] portrait:lg:pr-[0.55rem] portrait:lg:py-[0.75rem];

    @apply landscape:pl-[0.65rem] landscape:pr-[0.4rem] landscape:py-[0.35rem]
    landscape:jk:pl-[0.75rem] landscape:jk:pr-[0.45rem] landscape:jk:py-[0.4rem]
    landscape:lg:pl-[0.85rem] landscape:lg:pr-[0.55rem] landscape:lg:py-[0.5rem];

    @apply portrait:gap-x-[0.65rem] portrait:jk:gap-x-[0.5rem];
    @apply landscape:gap-x-[0.275rem] landscape:jk:gap-x-[0.325rem];

    @apply transition-all duration-300 ease-in-out;
}

.custom-phone-number-input-container button img {
    @apply aspect-auto;
    @apply portrait:w-[1.3rem] portrait:md:w-[0.95rem] portrait:jk:w-[1.05rem] portrait:lg:w-[1.45rem];
    @apply landscape:w-[0.8rem] landscape:md:w-[0.925rem] landscape:jk:w-[1rem] landscape:lg:w-[1.15rem];
}

.custom-phone-number-input-container button p {
    /*@apply portrait:text-[0.55rem] portrait:md:text-[0.775rem] portrait:jk:text-[0.95rem] portrait:lg:text-[1.15rem];*/
    /*@apply landscape:text-[0.6rem] landscape:md:text-[0.7rem] landscape:jk:text-[0.75rem] landscape:lg:text-[0.8rem];*/

    /*@apply portrait:leading-[0.75rem] portrait:md:leading-[0.9rem] portrait:lg:leading-[1rem];*/
    /*@apply landscape:leading-[0.8rem] landscape:md:leading-[1rem] landscape:lg:leading-[1.15rem];*/

    @apply portrait:text-[1.25rem] portrait:md:text-[0.85rem] portrait:jk:text-[1.15rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.725rem] landscape:md:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];

    @apply leading-tight font-custom-regular text-gray-900;
}

.custom-phone-number-input-container button svg {
    @apply aspect-square transition-all duration-300 ease-in-out;
    @apply portrait:w-[1rem] portrait:md:w-[0.525rem] portrait:jk:w-[0.6rem] portrait:lg:w-[0.675rem];
    @apply landscape:w-[0.5rem] landscape:md:w-[0.6rem] landscape:jk:w-[0.675rem] landscape:lg:w-[0.725rem];
}

.custom-phone-number-input-container input {
    @apply w-full flex-1 flex justify-between items-center;
    @apply landscape:rounded-r-md portrait:rounded-r-md portrait:jk:rounded-r-lg;

    @apply portrait:px-[0.95rem] portrait:py-[0.5rem] portrait:md:px-[0.75rem] portrait:md:py-[0.45rem] portrait:jk:px-[0.85rem] portrait:jk:py-[0.55rem] portrait:lg:px-[1rem] portrait:lg:py-[0.75rem];
    @apply landscape:px-[0.75rem] landscape:py-[0.35rem] landscape:jk:px-[0.85rem] landscape:jk:py-[0.4rem] landscape:lg:px-[1rem] landscape:lg:py-[0.5rem];

    @apply portrait:text-[1.25rem] portrait:md:text-[0.85rem] portrait:jk:text-[1.15rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.725rem] landscape:md:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];
    @apply leading-[1] font-custom-regular text-gray-900;

    @apply transition-all duration-300 ease-in-out outline-none;
}

.custom-phone-number-input-dropdown-button {
    @apply w-full flex items-center;

    @apply portrait:pl-[0.95rem] portrait:pr-[0.45rem] portrait:py-[0.5rem]
    portrait:md:pl-[0.7rem] portrait:md:pr-[0.35rem] portrait:md:py-[0.45rem]
    portrait:jk:pl-[0.9rem] portrait:jk:pr-[0.45rem] portrait:jk:py-[0.55rem]
    portrait:lg:pl-[1.1rem] portrait:lg:pr-[0.55rem] portrait:lg:py-[0.75rem];

    @apply landscape:pl-[0.65rem] landscape:pr-[0.4rem] landscape:py-[0.35rem]
    landscape:jk:pl-[0.75rem] landscape:jk:pr-[0.45rem] landscape:jk:py-[0.4rem]
    landscape:lg:pl-[0.85rem] landscape:lg:pr-[0.55rem] landscape:lg:py-[0.5rem];

    @apply portrait:gap-x-[0.65rem] portrait:jk:gap-x-[0.5rem];
    @apply landscape:gap-x-[0.275rem] landscape:jk:gap-x-[0.325rem];

    @apply transition-all duration-300 ease-in-out;
}

.custom-phone-number-input-dropdown-button img {
    @apply aspect-auto;
    @apply portrait:w-[1rem] portrait:md:w-[0.95rem] portrait:jk:w-[1.05rem] portrait:lg:w-[1.45rem];
    @apply landscape:w-[0.8rem] landscape:md:w-[0.925rem] landscape:jk:w-[1rem] landscape:lg:w-[1.15rem];
}

.custom-phone-number-input-dropdown-button p {
    /*@apply portrait:text-[0.55rem] portrait:md:text-[0.775rem] portrait:jk:text-[0.95rem] portrait:lg:text-[1.15rem];*/
    /*@apply landscape:text-[0.6rem] landscape:md:text-[0.7rem] landscape:jk:text-[0.75rem] landscape:lg:text-[0.8rem];*/

    @apply portrait:text-[1.25rem] portrait:md:text-[0.85rem] portrait:jk:text-[1.15rem] portrait:lg:text-[1.25rem];
    @apply landscape:text-[0.725rem] landscape:md:text-[0.8rem] landscape:jk:text-[0.9rem] landscape:lg:text-[1rem];


    /*@apply portrait:leading-[0.75rem] portrait:md:leading-[0.9rem] portrait:lg:leading-[1rem];*/
    /*@apply landscape:leading-[0.8rem] landscape:md:leading-[1rem] landscape:lg:leading-[1.15rem];*/

    @apply leading-tight font-custom-regular text-gray-900;
}

.custom-input-error-message {
    @apply flex-1 text-red-600 font-custom-regular;
    @apply portrait:text-[0.85rem] portrait:md:text-[0.75rem] portrait:jk:text-[0.95rem] portrait:lg:text-[1.15rem];
    @apply landscape:text-[0.45rem] landscape:md:text-[0.5rem] landscape:jk:text-[0.6rem] landscape:lg:text-[0.7rem];
}
